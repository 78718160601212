<template>
  <app-button
    v-if="resource.authorizations.thank"
    emphasis="low"
    hide-text
    :hide-icon="false"
    :icon-props="{ library: 'coolicon', name: 'happy' }"
    :label="t('ttmt.common.actions.thank')"
    @click="handleThanks"
  />

  <app-button
    v-if="resource.authorizations.getReceipt"
    emphasis="low"
    hide-text
    :hide-icon="false"
    :icon-props="{ library: 'coolicon', name: 'file_blank_outline' }"
    :label="t('ttmt.common.actions.get_receipt')"
    @click="handleReceipt"
  />
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import AppButton from '@shared/components/ui/AppButton.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Function called on thanks handling
  thanksCallback: {
    type: Function,
    default: () => ({}),
  },
  // Function called on receipt handling
  receiptCallback: {
    type: Function,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'thanked',
  'receipt_sent',
])

const { t } = useI18n()

// ---------- THANKS ----------

function handleThanks() {
  emits('thanked')
  props.thanksCallback()
}

// ---------- RECEIPT ----------

function handleReceipt() {
  emits('receipt_sent')
  props.receiptCallback()
}
</script>
